<template>
  <div>
    <TheSonyBar ref="sonyBar" />
    <TheNavBar ref="navBar" />
    <ClientOnly>
      <AstrobotPopIn v-if="displayAstrobotPopIn" />
      <TheIncentive
        v-else-if="displayIncentive"
        :incentive-type="incentiveType"
      />
    </ClientOnly>

    <slot />
    <TheFooter />
    <CookieBanner
      :need-cookie="needCookie"
      @change-cookie="changeCookie"
    />
    <ModalsContainer />
  </div>
</template>

<script setup lang="ts">
import type { TheNavBar, TheSonyBar } from '#components'
import type { ICrate } from '@/types/widgetbot'
import { joinURL } from 'ufo'
import { ModalsContainer, useVfm } from 'vue-final-modal'
import { isAstrobotContestRunning } from '~/utils/helpers'

declare global {
  interface Window {
    Crate: ICrate
  }
}

const runtimeConfig = useRuntimeConfig()
const route = useRoute()

const link = computed(() => [
  {
    rel: 'canonical',
    href: joinURL(runtimeConfig.public.baseUrl, route.fullPath),
  },
])

useHead({
  link,
})

const sonyBar = ref<InstanceType<typeof TheSonyBar> | null>(null)
const navBar = ref<InstanceType<typeof TheNavBar> | null>(null)

useComputeModalHeight(sonyBar, navBar)

const router = useRouter()
const vfm = useVfm()
const needCookie = ref(false)
const displayAstrobotPopIn = ref(true)

const allowCookie = useLocalStorage('allow-cookie', {
  cookie1: false,
  cookie2: false,
  cookie3: false,
})

router.afterEach((to, from) => {
  if (import.meta.server) return

  if (to.name !== from.name || to.name !== 'index') {
    vfm.closeAll()
  }

  needCookie.value = !allowCookie.value.cookie1

  checkDisplayAstrobotPopIn()
})

const { gtag } = useGtag()
const crossContentStore = useCrossContentStore()
const { crossContent } = storeToRefs(crossContentStore)

if (route.path === 'inscription') {
  gtag('event', 'virtualpage', {
    virtualUrl: route.path + '?v=' + crossContent.value?.bannerInfos.id,
    virtualTitle: route.meta.title,
  })
} else {
  gtag('event', 'virtualpage', {
    virtualUrl: route.path,
    virtualTitle: route.meta.title,
  })
}

if (route.path === '/page/cookies') {
  needCookie.value = true
}

const connectedBannerId = computed(
  () => crossContent.value?.bannerInfosConnected?.id,
)
const { authenticated } = useAuthenticated()

const authenticatedIncentiveIdCookie = useCookie<number | undefined>(
  'display-in-connected',
)

const isAuthenticatedIncentiveActive = computed(() => {
  if (
    !crossContent.value?.bannerInfosConnected?.display_start ||
    !crossContent.value?.bannerInfosConnected?.display_end
  ) {
    return false
  }

  const start = new Date(crossContent.value?.bannerInfosConnected.display_start)
  const end = new Date(crossContent.value.bannerInfosConnected.display_end)
  if (countdownS(start) <= 0 && countdownS(end) >= 0) {
    return true
  }
  return false
})

const displayAuthenticatedIncentive = computed(() => {
  if (!authenticated.value || needCookie.value) return false
  if (!connectedBannerId.value) return false
  if (connectedBannerId.value === authenticatedIncentiveIdCookie.value) {
    return false
  }

  return isAuthenticatedIncentiveActive.value
})

watch([displayAuthenticatedIncentive], (newDisplayAuthenticatedIncentive) => {
  if (newDisplayAuthenticatedIncentive) {
    authenticatedIncentiveIdCookie.value = undefined
  }
})

const publicIncentiveIdCookie = useCookie<number | undefined>('display-in')

const displayPublicIncentive = computed(() => {
  if (needCookie.value) return false
  if (authenticated.value) return false
  if (route.name === 'inscription') return false
  if (!crossContent.value?.bannerInfos?.id) return false

  return crossContent.value.bannerInfos.id !== publicIncentiveIdCookie.value
})

watch(displayPublicIncentive, (newDisplayPublicIncentive) => {
  if (newDisplayPublicIncentive) {
    publicIncentiveIdCookie.value = undefined
  }
})

const displayIncentive = computed(() => {
  return (
    (displayPublicIncentive.value || displayAuthenticatedIncentive.value) &&
    !isAstrobotContestRunning()
  )
})

const incentiveType = computed(() => {
  if (displayAuthenticatedIncentive.value) return 'connected'
  return 'unconnected'
})

const changeCookie = () => {
  needCookie.value = false

  checkDisplayAstrobotPopIn()
}

let widgetBot: Window['Crate'] | undefined = undefined

const checkDisplayAstrobotPopIn = () => {
  displayAstrobotPopIn.value =
    publicIncentiveIdCookie.value !== 3 &&
    isAstrobotContestRunning() &&
    route.name !== 'inscription' &&
    !needCookie.value
}

onMounted(async () => {
  await nextTick()
  const { discordChannel, discordServer } = runtimeConfig.public
  if (discordChannel && discordServer) {
    useScriptTag(
      'https://cdn.jsdelivr.net/npm/@widgetbot/crate@3',
      () => {
        widgetBot = new window.Crate({
          channel: discordChannel,
          server: discordServer,
          defer: true,
        })
      },
      {
        async: true,
        defer: true,
      },
    )
  }

  if (route.path === '/page/cookies') {
    needCookie.value = true
  } else {
    needCookie.value = !allowCookie.value.cookie1
  }

  checkDisplayAstrobotPopIn()
})

onUnmounted(() => {
  widgetBot?.hide()
})
</script>

<style>
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

.button--green {
  display: inline-block;
  padding: 10px 30px;
  color: #3b8070;
  text-decoration: none;
  border: 1px solid #3b8070;
  border-radius: 4px;
}

.button--green:hover {
  color: #fff;
  background-color: #3b8070;
}

.button--grey {
  display: inline-block;
  margin-left: 15px;
  padding: 10px 30px;
  color: #35495e;
  text-decoration: none;
  border: 1px solid #35495e;
  border-radius: 4px;
}

.button--grey:hover {
  color: #fff;
  background-color: #35495e;
}
</style>
