<template>
  <div
    v-if="needCookie"
    class="cookie"
    :class="{ param: showParam }"
  >
    <div
      v-if="showParam"
      class="param-band"
    >
      <div class="row">
        <div class="col col1">
          <h2>Paramétrer les cookies</h2>
          <p>
            Ce site utilise des cookies pour vous offrir une expérience
            utilisateur de qualité et mesurer l’audience. Cliquez sur chacun des
            cookies décrits ci- dessous pour activer / désactiver leur
            utilisation. Pour une utilisation optimale du site, il est conseillé
            de garder l’utilisation des différents cookies.
            <br />
            <br />Pour plus d’informations, consultez notre
            <a
              href="/page/politique-de-confidentialite"
              target="_blank"
              rel="noopener noreferrer"
              >Politique sur les cookies</a
            >.
          </p>
        </div>
        <div class="col col2">
          <h3 class="first">Fonctionnalités essentielles (obligatoire)</h3>
          <div class="cookie-detail">
            <div class="col-detail">
              Cookies indispensables au bon fonctionnement du site, ils ne
              peuvent être désactivés de nos systèmes. Si ces cookies sont
              bloqués par votre navigateur, des éléments du site ne pourront pas
              fonctionner.
            </div>
            <div class="col-switch needed">
              activé

              <div class="switch active">
                <div class="switch-content" />
              </div>
            </div>
          </div>
          <h3>Statistiques</h3>
          <div class="cookie-detail">
            <div class="col-detail">
              Il s'agit des cookies qui nous permettent de connaître
              l'utilisation et les performances du site et d'en améliorer le
              fonctionnement (par ex : les pages le plus souvent consultées,
              recherches,...)
            </div>
            <div
              class="col-switch"
              @click="cookie2 = !cookie2"
            >
              <p v-if="cookie2">activé</p>
              <p v-else>désactivé</p>
              <div
                class="switch"
                :class="{ active: cookie2 }"
              >
                <div class="switch-content" />
              </div>
            </div>
          </div>
          <h3>Publicitaires</h3>
          <div class="cookie-detail">
            <div class="col-detail">
              Il s'agit des cookies utilisés pour vous présenter des publicités
              ou vous adresser des informations adaptées à vos centres
              d'intérêts sur notre site ou en dehors, lors de votre navigation.
            </div>
            <div
              class="col-switch"
              @click="cookie3 = !cookie3"
            >
              <p v-if="cookie3">activé</p>
              <p v-else>désactivé</p>
              <div
                class="switch"
                :class="{ active: cookie3 }"
              >
                <div class="switch-content" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons">
        <div
          class="button ok-button"
          @click="setCookies"
        >
          Enregistrer les préférences
        </div>
        <div
          class="button param-button"
          @click="disallowCookies"
        >
          Tout refuser
        </div>
        <div
          class="button ok-button"
          @click="allowCookies"
        >
          Tout accepter
        </div>
      </div>
    </div>
    <div
      v-else
      class="init-band"
    >
      <div class="text">
        En poursuivant votre navigation sur ce site, vous acceptez le dépôt de
        cookies de Sony Interactive Entertainement France et de tiers, destinés
        à mesurer votre fréquentation sur notre site, et à vous proposer des
        contenus personnalisés, des services, offres ou publicités, adaptés en
        fonction de vos centres d’intérêt. En savoir plus sur notre
        <a
          href="/page/politique-de-confidentialite"
          target="_blank"
          rel="noopener noreferrer"
          >politique de confidentialité</a
        >
        et l’utilisation des
        <a
          href="/page/cookies"
          target="_blank"
          rel="noopener noreferrer"
          >cookies</a
        >
        .
      </div>
      <div class="buttons">
        <div
          class="button ok-button"
          @click="allowCookies"
        >
          Accepter
        </div>
        <div
          class="button ok-button"
          @click="disallowCookies"
        >
          Refuser
        </div>
        <div
          class="button param-button"
          @click="showParams"
        >
          Paramétrer
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    needCookie?: boolean
  }>(),
  {
    needCookie: true,
  },
)
const emit = defineEmits(['changeCookie'])

const { gtag, initialize } = useGtag()

const showParam = ref(false)
const cookie1 = true
const cookie2 = ref(false)
const cookie3 = ref(false)

const showParams = () => {
  showParam.value = true
}

const allowCookies = () => {
  cookie2.value = true
  cookie3.value = true
  setCookies()
}

const pixelConsent = useConsent()

const disallowCookies = () => {
  pixelConsent.revokeConsent()
  cookie2.value = false
  cookie3.value = false
  setCookies()
}

const metaTracker = usePixelMeta()
const twitterTracker = usePixelTwitter()

const setCookies = () => {
  const payload = {
    cookie1,
    cookie2: cookie2.value,
    cookie3: cookie3.value,
  }

  const cookieStorage = useLocalStorage('allow-cookie', payload)
  cookieStorage.value = payload

  if (cookie2.value) {
    initialize()
    gtag('event', 'clicbandeaucookie', {
      cta_name: `Accepter`,
    })
  }

  if (cookie3.value) {
    pixelConsent.grantConsent()
    metaTracker.enable()
    twitterTracker.enable()
  }

  emit('changeCookie')
}
</script>

<style lang="scss" scoped>
.cookie {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  padding: 0 8rem;
  color: $white;
  background: rgba($black, 0.9);

  @media screen and (max-width: 700px) {
    padding: 0 2.5rem;
  }

  @media screen and (max-width: 500px) {
    &.param {
      height: stretch;
    }
  }

  .button {
    display: flex;
    flex: 1 0 100%;
    align-items: center;
    height: 4.6rem;
    margin-bottom: 1.6rem;
    padding: 0 2rem;
    font-size: 1.4rem;
    font-weight: 500;
    cursor: pointer;

    @media screen and (min-width: 500px) {
      flex: 0 0 auto;
      margin-right: 1.6rem;
      margin-bottom: 0;
    }

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }

    &.ok-button {
      background: $backgroundBlue;
    }

    &.param-button {
      border: 1px solid $white;
    }
  }

  .init-band {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4rem 0;

    @media screen and (max-width: 700px) {
      flex-direction: column;
    }

    .text {
      flex-basis: 60%;
      font-size: 1.4rem;

      @media screen and (max-width: 700px) {
        flex-basis: auto;
      }

      a {
        color: $white;
      }
    }

    .buttons {
      display: flex;

      @media screen and (max-width: 500px) {
        flex-wrap: wrap;
      }

      @media screen and (max-width: 700px) {
        justify-content: flex-end;
        width: 100%;
        margin-top: 4rem;
      }
    }
  }

  .param-band {
    padding: 7rem 0;

    @media screen and (max-width: 700px) {
      flex-direction: column;
    }

    @media screen and (max-width: 500px) {
      top: 0;
      right: 0;
      height: 100%;
      overflow-y: auto;
      transform: translate3d(0, 0, 0);

      &::-webkit-scrollbar {
        display: none; // Safari and Chrome
      }
    }

    .row {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 700px) {
        flex-direction: column;
      }

      .col1 {
        flex-basis: 36%;
        padding-right: 8%;

        h2 {
          margin-bottom: 3rem;
          font-family: SST;
          font-size: 3.2rem;
          font-weight: 400;
          line-height: 1.3;
          letter-spacing: 0;
          text-transform: none;
        }

        a {
          color: $white;
        }
      }

      .col2 {
        flex-basis: 64%;
        padding-left: 8%;
        border-left: 1px solid rgba($white, 0.2);

        @media screen and (max-width: 700px) {
          margin-top: 4rem;
          border: 0;
        }

        h3 {
          margin-top: 2.6rem;
          margin-bottom: 1rem;
          font-family: SST;
          font-size: 1.8rem;
          font-weight: 400;
          letter-spacing: 0;

          &.first {
            margin-top: 0;
          }
        }

        .cookie-detail {
          display: flex;
          justify-content: space-between;

          @media screen and (max-width: 500px) {
            flex-direction: column;
          }

          .col-detail {
            width: 65%;
            font-size: 1.4rem;
            line-height: 1.3;

            @media screen and (max-width: 500px) {
              width: 100%;
            }
          }

          .col-switch {
            display: flex;
            align-items: center;
            font-family: 'SST Condensed';
            font-weight: 500;
            text-transform: uppercase;

            @media screen and (max-width: 500px) {
              margin-top: 2rem;
            }

            &.needed {
              opacity: 0.5;
              pointer-events: none;
            }

            .switch {
              position: relative;
              width: 7.2rem;
              height: 2.4rem;
              margin-left: 2rem;
              background: #c1c1c1;
              border-radius: 1.2rem;

              .switch-content {
                position: absolute;
                top: 0.4rem;
                left: 0.4rem;
                width: 3.4rem;
                height: 1.6rem;
                background: $white;
                border-radius: 0.8rem;
                transition: left 0.4s;
              }

              &.active {
                background: $secondaryBlue;

                .switch-content {
                  left: 3.4rem;
                }
              }
            }
          }
        }
      }

      .col1,
      .col2 {
        @media screen and (max-width: 700px) {
          flex-basis: auto;
          padding-right: 0;
          padding-left: 0;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 7.2rem;

      @media screen and (max-width: 700px) {
        flex-wrap: wrap;
      }
    }
  }
}
</style>
