<template>
  <div
    ref="banner"
    class="banner"
    :class="{
      'banner--show': show,
      'banner--hide': hide,
      'banner--sticky': sticky,
    }"
    :style="`--h: ${height}`"
  >
    <ButtonClose
      class="banner__close"
      @click="close"
    />
    <div class="banner__container">
      <div class="banner__content">
        <div class="banner__text">
          <header class="banner__header">
            <img
              src="~/assets/img/astrobot-logo.png"
              alt="logo Astro Bot"
              class="banner__logo"
            />
          </header>
          <div>
            <p class="banner__title">Concours - Où est Astro ?</p>
            <p class="banner__intro">
              Astro Bot s’est caché parmi son équipage… Retrouvez-le et tentez
              de gagner 10 jeux Astro Bot et plein d’autres cadeaux !
            </p>
          </div>
          <ButtonSimple
            route="/astrobot"
            label="Participer"
            color="white"
            class="banner__button"
          />
        </div>
        <img
          src="~/assets/img/astrobot-goodies.png"
          class="banner__image-goodies"
          alt="Astro Bot et des goodies"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const show = ref(false)
const hide = ref(false)
const sticky = ref(false)
const height = ref('0')
const banner = ref<HTMLDivElement | null>(null)
const publicIncentiveIdCookie = useCookie<number | undefined>('display-in', {
  path: '/',
  maxAge: 60 * 60 * 24 * 60,
})

const init = () => {
  onResize()

  setTimeout(() => {
    show.value = true

    setTimeout(() => {
      sticky.value = true
    }, 550)
  }, 2000)
  useEventListener('resize', onResize)
}

const onResize = () => {
  const h =
    banner.value !== null ? banner.value.getBoundingClientRect().height : null

  if (h) {
    height.value = `${h}px`
  }
}

const close = () => {
  hide.value = true
  sticky.value = false
  show.value = false
  publicIncentiveIdCookie.value = 3
}

onMounted(() => {
  init()
})
</script>

<style lang="scss" scoped>
.banner {
  --h: 0;
  border-bottom: 4px solid #0148a7;
  position: absolute;
  top: -100vh;
  display: flex;
  justify-content: center;
  margin-top: calc(var(--h) * -1);
  color: white;
  overflow: hidden;
  background-image: url('/img/astrobot-banner-bg.jpg');
  background-size: cover;

  &--show,
  &--hide {
    position: relative;
    top: 0;
    z-index: 8;
    transition: margin 0.35s cubic-bezier(0.62, 0.28, 0.23, 0.99);
  }

  &--show {
    margin-top: 0;
  }

  &--sticky {
    position: sticky;
    top: 7rem;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1280px;
    justify-content: flex-end;
    align-items: center;
    height: 290px;

    // Media query to detect when the content wraps
    @media (max-width: 1324px) {
      align-items: center; // Center-aligns the content vertically when wrapped
      justify-content: center;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    min-width: 470px;
    flex-grow: 1;
    height: 100%;
    @media (max-width: 620px) {
      min-width: auto;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    justify-content: space-evenly;
    max-width: 560px;
    padding-left: 25px;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  &__header {
    text-align: left;
  }

  &__logo {
    width: 300px;
    height: auto;
    padding-top: 2rem;
  }

  &__title {
    line-height: 1;
    display: inline-block;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 700;
    width: auto;
    text-align: left;
    padding-top: 4px;
    margin-bottom: 1rem;

    @media (max-width: 488px) {
      font-size: 2.6rem;
    }
  }

  &__intro {
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 1.6rem;

    @media (max-width: 620px) {
      margin-bottom: 0.5rem;
    }

    @media (max-width: 488px) {
      margin-bottom: 0;
    }
  }

  &__image-goodies {
    align-self: center;
    max-width: 705px;
    height: 220px;

    @media (max-width: 800px) {
      height: auto;
      width: 50%;
    }

    @media (max-width: 600px) {
      position: absolute;
      z-index: -1;
      height: 70%;
      width: auto;
      opacity: 0.4;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
</style>
