<template>
  <div>
    <div class="footer">
      <div class="footer__main">
        <div class="brand-img">
          <img
            src="~/assets/icons/playstationWhite.svg"
            alt="logo PlayStation"
            width="53"
            height="41"
          />
        </div>
        <ul class="links">
          <li>
            <NuxtLink to="/page/conditions-generales-dutilisation"
              >Conditions générales d’utilisation</NuxtLink
            >
          </li>
          <li>
            <NuxtLink to="/page/politique-de-confidentialite"
              >Politique de confidentialité</NuxtLink
            >
          </li>
          <li>
            <NuxtLink to="/page/cookies">Cookies</NuxtLink>
          </li>
          <li>
            <NuxtLink to="/page/mentions-legales">Mentions légales</NuxtLink>
          </li>
          <li>
            <NuxtLink to="/page/faq">FAQ</NuxtLink>
          </li>
          <li>
            <a href="mailto:contacts@weareplaystation.fr">Contacts</a>
          </li>
          <li>
            <a
              href="http://askaboutgames.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Askaboutgames.com
            </a>
          </li>
          <li>
            <a
              href="http://playStation.com/parents"
              target="_blank"
              rel="noopener noreferrer"
            >
              PlayStation.com/parents
            </a>
          </li>
          <li>
            <a
              href="http://playStation.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              PlayStation.com
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer">
      <div class="footer__mentions">
        <div>
          Website ©{{ new Date().getUTCFullYear() }} Sony Interactive
          Entertainment Europe Limited. Tout le contenu, les titres de jeux,
          marques, marques commerciales, graphismes et imagerie connexe sont la
          propriété intellectuelle de leurs détenteurs respectifs. Tous droits
          réservés.
        </div>
        <div>a Sony Company</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer {
  z-index: 1;
  padding: 4rem 8rem;
  color: $white;
  background: $blue;

  @media screen and (max-width: 700px) {
    padding: 2.5rem;
  }

  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .brand-img {
      img {
        width: 5.3rem;
        height: 4.1rem;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      text-align: right;
      list-style: none;

      @media screen and (max-width: 700px) {
        flex-flow: column nowrap;
      }

      li {
        margin-left: 3rem;

        &:first-child {
          margin-left: 0;
        }

        a {
          color: $white;
          font-size: 1.2rem;
          line-height: 1.6rem;
          text-decoration: none;
        }
      }
    }
  }

  &__mentions {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 1.2rem;

    @media (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      div:first-child {
        flex-basis: 75%;
      }
    }

    div:last-child {
      text-align: right;
    }
  }

  + .footer {
    border-top: 1px solid rgba($white, 0.15);
  }
}
</style>
