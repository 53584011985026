<template>
  <div
    ref="bannerRef"
    class="banner"
    :class="{
      'banner--show': show,
      'banner--hide': hide,
      'banner--sticky': sticky,
    }"
  >
    <div
      v-if="incentiveType === 'connected'"
      class="banner__container"
    >
      <div class="banner__content">
        <header class="banner__header">
          <p class="banner__title">
            {{ bannerInfosConnected?.supTitle }}
          </p>
        </header>
        <p class="banner__intro">
          {{ bannerInfosConnected?.intro }}
        </p>
        <div
          v-if="
            bannerInfosConnected?.image_desktop?.url &&
            (isMobile || isLargeMobile || isTabletPortrait || isSmallScreen)
          "
        >
          <div class="banner__img">
            <a
              @click="
                () => {
                  gtag('event', 'clicpopinconnecte', {
                    cta_name: bannerInfosConnected?.link_button,
                  })
                  authenticatedIncentiveIdCookie = bannerInfosConnected?.id
                  goToURL()
                }
              "
            >
              <img
                :src="
                  bannerInfosConnected?.image_mobile?.url ??
                  bannerInfosConnected.image_desktop.url ??
                  ''
                "
                :alt="bannerInfosConnected.intro"
            /></a>
          </div>
        </div>
        <div
          v-if="bannerInfosConnected?.link_button"
          class="banner__action"
        >
          <ButtonSimple
            :label="bannerInfosConnected.link_button"
            color="white"
            @click="
              () => {
                gtag('event', 'clicpopinconnecte', {
                  cta_name: bannerInfosConnected?.link_button,
                })
                authenticatedIncentiveIdCookie = bannerInfosConnected?.id
                goToURL()
              }
            "
          />
        </div>
      </div>
      <div
        v-if="
          bannerInfosConnected?.image_desktop?.url &&
          (isTabletLandscape || isDesktop)
        "
      >
        <div class="banner__img">
          <a
            @click="
              () => {
                gtag('event', 'clicpopinconnecte', {
                  cta_name: bannerInfosConnected?.link_button,
                })
                authenticatedIncentiveIdCookie = bannerInfosConnected?.id

                useCookie<number>('display-in-connected', {
                  path: '/',
                  maxAge: 60 * 60 * 24 * 60,
                })
                goToURL()
              }
            "
          >
            <img
              :src="bannerInfosConnected.image_desktop.url"
              :alt="bannerInfosConnected.intro"
          /></a>
        </div>
      </div>
      <ButtonClose
        class="banner__close"
        @click="closeConnected"
      />
    </div>
    <div
      v-else
      class="banner__container"
    >
      <div class="banner__content">
        <header class="banner__header">
          <p class="banner__title">REJOIGNEZ LA COMMUNAUTé</p>
        </header>
        <p class="banner__intro">
          {{ bannerInfos?.intro }}
        </p>
        <div
          v-if="
            bannerInfos?.image_desktop?.url &&
            (isMobile || isLargeMobile || isTabletPortrait || isSmallScreen)
          "
        >
          <div class="banner__img">
            <NuxtLink
              to="/inscription"
              @click="
                () => {
                  gtag('event', 'clicinscription', {
                    cta_name: `hphaut_S'inscrire-${bannerInfos?.id}`,
                  })
                  incentiveIdCookie = bannerInfos?.id
                }
              "
            >
              <img
                :src="
                  bannerInfos?.image_mobile?.url ??
                  bannerInfos.image_desktop.url ??
                  ''
                "
                :alt="bannerInfos.intro"
            /></NuxtLink>
          </div>
        </div>
        <div class="banner__action">
          <ButtonSimple
            route="/inscription"
            label="S'inscrire"
            color="white"
            @click="
              () => {
                gtag('event', 'clicinscription', {
                  cta_name: `hphaut_S'inscrire-${bannerInfos?.id}`,
                })
                incentiveIdCookie = bannerInfos?.id
              }
            "
          />
        </div>
      </div>
      <div
        v-if="
          bannerInfos?.image_desktop?.url && (isTabletLandscape || isDesktop)
        "
      >
        <div class="banner__img">
          <NuxtLink
            to="/inscription"
            @click="
              () => {
                gtag('event', 'clicinscription', {
                  cta_name: `hphaut_S'inscrire-${bannerInfos?.id}`,
                })
                incentiveIdCookie = bannerInfos?.id
              }
            "
          >
            <img
              :src="bannerInfos.image_desktop.url"
              :alt="bannerInfos.intro"
            />
          </NuxtLink>
        </div>
      </div>
      <ButtonClose
        class="banner__close"
        @click="close"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  incentiveType: 'connected' | 'unconnected'
}>()

const { gtag } = useGtag()
const {
  isMobile,
  isLargeMobile,
  isSmallScreen,
  isTabletPortrait,
  isTabletLandscape,
  isDesktop,
} = useCustomMediaQuery()

const show = ref(false)
const hide = ref(false)
const sticky = ref(false)

const bannerInfos = computed(() => crossContentStore.crossContent?.bannerInfos)
const bannerInfosConnected = computed(
  () => crossContentStore.crossContent?.bannerInfosConnected,
)

const bannerRef = ref<HTMLElement | null>(null)

const crossContentStore = useCrossContentStore()

const init = () => {
  setTimeout(() => {
    show.value = true

    setTimeout(() => {
      sticky.value = true
    }, 550)
  }, 2000)
}

const isIncentiveActive = computed(() => {
  if (props.incentiveType !== 'connected') return false
  if (
    !bannerInfosConnected.value?.display_start ||
    !bannerInfosConnected.value?.display_end
  ) {
    return false
  }
  const start = new Date(bannerInfosConnected.value?.display_start)
  const end = new Date(bannerInfosConnected.value?.display_end)

  return countdownS(start) <= 0 && countdownS(end) >= 0
})

if (props.incentiveType !== 'connected' || isIncentiveActive.value) {
  init()
}

const { height } = useElementBounding(bannerRef)

const heightCSS = computed(() => `${height.value}px`)

const goToURL = () => {
  window.open(bannerInfosConnected.value?.link_url, '_self')
}
const authenticatedIncentiveIdCookie = useCookie<number | undefined>(
  'display-in-connected',
  {
    path: '/',
    maxAge: 60 * 60 * 24 * 60,
  },
)
const incentiveIdCookie = useCookie<number | undefined>('display-in', {
  path: '/',
  maxAge: 60 * 60 * 24 * 60,
})

const close = () => {
  hide.value = true
  sticky.value = false
  show.value = false

  incentiveIdCookie.value = bannerInfos.value?.id
  // emit to remove after 1s
}

const closeConnected = () => {
  hide.value = true
  sticky.value = false
  show.value = false

  incentiveIdCookie.value = bannerInfos.value?.id
  // emit to remove after 1s
}

const { authenticated } = useAuthenticated()

watch(authenticated, (newAuthenticated) => {
  if (!newAuthenticated) return

  if (props.incentiveType !== 'connected') {
    return close()
  }

  if (isIncentiveActive.value) {
    return init()
  }
})
</script>

<style lang="scss" scoped>
.banner {
  position: absolute;
  top: -100vh;
  margin-top: calc(v-bind(heightCSS) * -1);
  padding: 40px;
  color: #fff;
  background-color: #0f6eba;
  background-image: url('~/assets/img/step2bg.jpg');
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover;

  @media (min-width: 993px) {
    padding: 11px 0;
    background-position: center bottom;
  }

  &--show,
  &--hide {
    position: relative;
    top: 0;
    z-index: 8;
    transition: margin 0.35s cubic-bezier(0.62, 0.28, 0.23, 0.99);
  }

  &--show {
    margin-top: 0;
  }

  /* &--hide {
    position: relative;
    top: 0;
  } */

  &--sticky {
    position: sticky;
    top: 7rem;
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;

    @media (min-width: 992px) {
      flex-direction: row;
      align-items: center;
      max-width: 940px;
      min-height: 250px;
      margin: 0 auto;
    }
  }

  &__content {
    @media (min-width: 992px) {
      width: 44%;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    @media (min-width: 992px) {
      // margin-bottom: 3rem;
      // padding-top: 1.5rem;
    }
  }

  &__action {
    @media (min-width: 992px) {
      // margin-bottom: 3rem;
    }
  }

  &__title {
    font-family: 'SST Condensed';
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;

    @media (min-width: 992px) {
      top: 1.5rem;
    }
  }

  &__intro {
    margin-bottom: 0;
    font-size: 2rem;
    line-height: 1.6;

    @media (min-width: 992px) {
      margin-bottom: 3rem;
    }
  }

  &__img {
    flex-shrink: 0;
    width: 430px;
    height: 220px;
    margin: 0 40px;

    @media (max-width: 991px) {
      width: 100%;
      height: auto;
      margin: 0;
    }

    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
</style>
